// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asasc-2019-js": () => import("./../../../src/pages/ASASC2019.js" /* webpackChunkName: "component---src-pages-asasc-2019-js" */),
  "component---src-pages-asasc-2019-v-2-js": () => import("./../../../src/pages/ASASC2019v2.js" /* webpackChunkName: "component---src-pages-asasc-2019-v-2-js" */),
  "component---src-pages-asasc-2020-v-3-js": () => import("./../../../src/pages/ASASC2020v3.js" /* webpackChunkName: "component---src-pages-asasc-2020-v-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

